// faculty css
.rts-faculty{}
.single-staff{
    border: 1px solid var(--rt-line);
    padding: 20px;
    transition: var(--transition);
    &__content{
        display: flex;
        gap: 30px;
        align-items: center;
        @include mq($lg){
            flex-wrap: wrap;
        }
        @include mq($md){
            flex-wrap: nowrap;
        }
        @include mq($xs){
            flex-wrap: wrap;
        }
        .staf-image{
            width: 100%;
            @include mq($md){
                width: auto;
            }
            @include mq($xs){
                width: 100%;
            }
            img{
                
                min-width: 300px;
                height: 360px;
                @include mq($lg){
                    width: 100%;
                    object-fit: cover;
                    object-position: top;
                }
                @include mq($xs){
                    min-width: 100%;
                }
            }
        }
        .staf-info{
            .name{
                font-weight: 600;
                margin-bottom: 5px;
            }
            .designation{
                display: block;
                margin-bottom: 15px;
            }
            &__social{
                margin-bottom: 15px;
                display: flex;
                gap: 10px;
                i{
                    font-size: 16px;
                    color: var(--rt-body);
                    transition: var(--transition);
                    &:hover{
                        color: var(--rt-primary);
                    }
                }
            }
            .email-contact, .phone-contact{
                display: block;
                display: flex;
                gap: 10px;
                margin-bottom: 10px;
                span{
                    color: var(--rt-primary);
                }
            }
            .phone-contact{
                margin-bottom: 0;
            }
            &__speciality{
                margin-top: 25px;
                border-left: 2px solid var(--rt-theme);
                margin-bottom: 30px;
                p{
                    margin-left: 15px;
                }
            }
        }
        
    }
    &:hover{
        border-color: var(--rt-primary);
    }
}
// faculty details
.rts-faculty-details{

}
.faculty-member{
    &__details{}
    &__image{
        margin-bottom: 30px;
        img{
            height: 100%;
            max-width: 100%;
            object-fit: cover;
            @include mq($md){
                width: 100%;
                
            }
        }

    }
    &__info{
        display: block;
        &--social{
            margin-bottom: 10px;
            display: flex;
            gap: 10px;
            justify-content: center;
            i{
                font-size: 16px;
            }

        }
        .email-contact, .phone-contact{
            display: flex;
            gap: 10px;
            justify-content: center;
            span{
                color: var(--rt-primary);
            }
        }
        .email-contact{
            margin-bottom: 10px;
        }
    }
}
.member-info{
    margin-left: 40px;
    @include mq($md){
        margin-left: 0;
    }
    &__details{
        .short-info{
            &:not(:last-child){
                padding-bottom: 50px;
                border-bottom: 1px solid var(--rt-border);
            }
            &:not(:first-child){
                padding-top: 50px;
            }
            .designation{
                margin-bottom: 25px;
                display: block;
                font-size: 20px;
            }
            h5.rts-section-title{
              margin-bottom: 15px;  
            }
            .description{
                &:not(:last-child){
                    margin-bottom: 35px;
                }
            }
            .course__single{
                ul{
                    padding: 0;
                    margin: 0;
                    li{
                        margin-top: 10px;
                        margin-bottom: 0;
                        transition: var(--transition);
                        &:hover{
                            a{
                                text-decoration: underline;
                            }
                        }
                        a{
                            color: var(--rt-primary);
                        }
                    }
                }
            }
        }
    }
}