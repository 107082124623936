/*=== Scss File indexing Here===========

1. variables
2.typography
3.spacing
4.reset
5.forms
6.mixins
7.shortcode
8.animations
9.text-animation'

========================================*/

/* Default  */

@import "default/variables";
@import "default/typography";
@import "default/spacing";
@import "default/reset";
@import "default/forms";
@import "default/mixins";
@import "default/shortcode";
@import "default/animations";
@import "default/text-animation";
@import"elements/common";

/* Header */
@import"header/header";
@import"header/nav";
@import"header/mobile-menu";


/* Banner */
@import"elements/button";
@import"banner/banner-one";
@import"elements/breadcrumb";


/* elements */
@import"elements/banner";
@import"elements/brand";
@import"elements/about";
@import"elements/feature";
@import"elements/campus";
@import"elements/application";
@import"elements/service";
@import"elements/course";
@import"elements/events";
@import"elements/fun-facts";
@import"elements/instructor";
@import"elements/testimonials";
@import"elements/blog";
@import"elements/cta";
@import"elements/footer";

@import"elements/filter";
@import"elements/search-input";
@import"elements/cart-bar";
@import"elements/nice-select";

@import"elements/backto-top";
@import"elements/benefits";
@import"elements/zoom";
@import"elements/course-single";
@import"elements/contact";
@import"elements/offcanvase";









