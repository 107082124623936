// header style here

.header-top-one-wrapper {
    padding: 6px 0;

    .header-top-one {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--rt-white);
    }

    .left-information {
        display: flex;
        align-items: center;
        gap: 30px;
        @include mq($xs){
            display: none;
        }
        a {
            color: var(--rt-white);
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: var(--p-xs);
            font-style: normal;
            font-weight: 500;
        }
    }

    .right-information {
        display: flex;
        align-items: center;
        gap: 15px;
        @include mq($xs){
            width: 100%;
            justify-content: center;
            gap: 20px;
        }
        // dropdown menu
        .rts-dropdown-menu {
            padding: 0;
            margin: 0;
            list-style: none;
            position: relative;
            .has-child-menu{
                margin: 0;
                span, i{
                    color: var(--rt-white);
                    font-size: 14px;
                }
                &:hover{
                    ul{
                        opacity: 1;
                        visibility: visible;
                        left: -100%;
                    }
                }
            }
            ul.sub-menu {
                list-style: none;
                padding: 10px;
                margin: 0;
                min-width: 120px;
                background: var(--rt-white);
                position: absolute;
                top: 120%;
                z-index: 9;
                opacity: 0;
                transition: var(--transition);
                visibility: hidden;
                li {
                    margin-top: 5px;
                    margin-bottom: 5px;
                    padding: 0 10px;
                    background: var(--rt-border);
                    a{
                        display: block;
                        span{
                            color: var(--rt-secondary);
                        }
                    }
                }

            }
        }

        select {
            display: inline-block;
            max-width: max-content;
            background-color: transparent;
            border: none;
            color: var(--rt-white);
            font-size: var(--p-xs);
            position: relative;
            background-image: url(../images/header/01.svg) !important;

            &::after {
                position: absolute;
                content: '\f078';
                right: 0;
                top: 0;
                font-size: 14px;
                color: var(--rt-white);
            }

            &:focus {
                box-shadow: none;
            }

            option {
                color: #242424;
                font-size: var(--p-xs);
            }
        }
    }
}

.header-one-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-side-header {
        display: flex;
        align-items: center;
        gap: 70px;

        .logo-area {
            padding: 20px 0;
            display: block;
        }

        .category-area {
            cursor: pointer;

            .category-btn {
                padding: 10px 16px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                border: 1px solid #BBB1F2;
                gap: 8px;

                span {
                    color: #553CDF;
                    font-weight: 500;
                }

                i {
                    color: #553CDF;
                }
            }
        }
    }
}

.header-right-area-one {
    display: flex;
    align-items: center;
    gap: 30px;

    .buttons-area {
        display: flex;
        align-items: center;
        gap: 15px;

        .rts-btn {
            padding: 8px 20px;
            font-size: var(--p-xs);
            font-weight: 600;
        }
    }

    .actions-area {
        display: flex;
        align-items: center;

        a.rts-btn {
            padding: 13px 25px;
            margin-left: 30px;

            @media #{$smlg-device} {
                display: none;
            }
        }

        .menu-btn {
            cursor: pointer;
            margin-left: 30px;
        }

        .search-btn {
            cursor: pointer;

            i {
                color: #000;
            }
        }

        .cart {
            margin-left: 30px;

            i {
                color: var(--rt-primary);
                font-size: 22px;
                position: relative;
                margin-top: 8px !important;
                cursor: pointer;

                &::after {
                    position: absolute;
                    content: "2";
                    right: -8px;
                    bottom: var(--p-xs);
                    height: 20px;
                    width: 20px;
                    background: var(--rt-primary);
                    border-radius: 50%;
                    font-size: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--rt-white);
                }
            }
        }
    }

    .header-right-content {
        display: flex;
        gap: 20px;
        align-items: center;

        .search-form {
            max-width: 220px;
            height: 40px;
            border: 1px solid var(--rt-border);
            position: relative;
            display: flex;
            align-items: center;
            @include mq($xs){
                display: none;
            }
            button[type=submit] {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-size: var(--p-s);
                width: auto;
                border: var(--rt-none);
            }
        }

        .menu-bar {
            cursor: pointer;
        }
    }
}

.header-one.v-2 {
    .header-top-one-wrapper {}

    .header-top-one-wrapper .left-information a {
        color: #737477;
    }

    .header-top-one-wrapper .left-information i {
        color: var(--rt-primary);
        font-weight: 500;
    }

    .header-top-one-wrapper .right-information select {
        color: #737477;
        background-image: url(../images/header/02.svg) !important;
    }
}