
// navigation area

.nav-area{
    nav{
        ul{
            display: flex;
            align-items: center;
            margin: 0;
            li{
                margin: 0;
                a{
                    padding: 33px 22px !important;
                    color:  #110C2D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 150% */
                    display: block;
                }
            }
        }
    }
}

nav{
    ul{
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        li{
            margin: 0;
            a{
                padding: 19px 15px;
                display: block;
                color: #1F1F25;
                font-weight: 700;
                transition: .3s;
            }
        }
    }
}

// sticky header
.header--sticky {
    position: relative;
    top: 0;
    display: block;
    width: 100%;
    box-shadow: none;
    background: #fff;
    z-index: 999;
    margin: auto;
    align-items: center;
    left: 0;
    border-radius: 0;
    transition: all 0.5s ease-in-out;
    top: 0;
    transform: translateY(0);
    &.fixed{
        position: fixed !important;
        box-shadow: 0px 7px 18px #1810100d;
    }
}
.header--sticky.back-hide-header {
    transform: translateY(-100%);
    transition: var(--transition);
}

// drop down menu main header
.header-one{
    .header-nav.main-nav-one {
        margin-left: -12px;
    }
}
.main-nav-one{
    @media #{$md-layout} {
        display: none;
    }
    @media #{$sm-layout} {
        display: none;
    }
    ul{
        li{
            position: relative;
            &>a{
                padding: 19px 15px;
            }
            a{
                &.nav-link{
                    color: #1F1F25;
                    font-size: 16px;
                    font-weight: 400;
                    @media #{$md-layout} {
                        font-size: 14px;
                        margin-right: 8px;
                    }
                }
            }
            &.has-dropdown{
                margin-right: 25px;
                position: relative;
                a{
                    position: relative;
                    &.nav-link{
                        padding: 31px 15px;
                        color: #1F1F25;
                        font-size: 16px;
                        font-weight: 400;
                    }
                    &::before{
                        content: "\f078" !important;
                        position: absolute !important;
                        top: 50%;
                        font-family: "Font Awesome 5 Pro" !important;
                        right: -3px;
                        transform: translateY(-50%);
                        transition: 0.3s;
                        font-size: 12px;                  
                    }
                }
                .submenu{
                    min-width: 230px;
                    height: auto;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 90;
                    opacity: 0;
                    visibility: hidden;
                    text-align: left;
                    transition: 0.3s;
                    transform: translateY(-20px);
                    border-radius: 0;
                    background-color: #fff;
                    border-left: 1px solid #ffffff14;
                    border-bottom: 1px solid #ffffff14;
                    border-right: 1px solid #ffffff14;
                    display: inline-block;
                    box-shadow: 0 36px 35px rgba(0, 0, 0, 0.08);
                    li{    
                        margin-right: 0;
                        padding: 0 15px;
                        &:last-child{
                            a{
                                border: none;
                            }
                        }
                        a{
                            padding:12px 0 12px 0;
                            border-bottom: 1px solid #ebebeb;
                            font-weight: 400;
                            font-size: 16px;
                        }
                        a{
                            &::before{
                                display: none;
                            }
                        }
                    }
                }
                &:hover{
                    a.nav-link{
                        &::before{
                            content: "\f077" !important;
                        }
                    }
                    .submenu{
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                        transform: translateY(0);
                    }
                }
            }
            &:hover{
                a{
                    &.nav-link{
                        color: var(--rt-primary);
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}




.sub-dropdown{
    position: relative !important;
    display: block !important;

    &::after{
        position: absolute;
        content: '\f054';
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #616165;
        font-size: 16px;
        font-family: var(--fontawesome);
    }
    .submenu.third-lvl.base{
        opacity: 0 !important;
        min-width: 185px !important;
        left: 100% !important;
        top: -13% !important;
        margin: 0;
        border-radius: 0 !important;
        a{
            &:not(:last-child){
                border-bottom: 1px solid #ebebeb !important;
            }
        }
        &.base{
            display: block !important;
        }
    }
    &:hover{
        .sub-menu-link{
            color: var(--rt-primary);
        }
        .submenu.third-lvl.base{
            opacity: 1!important;
            top: 0 !important;
            right: 3px;
            display: block !important;
            border-radius: 0 !important;
            z-index: 100000 !important;
            li{
                display: block;
                a{
                    display: block !important;
                }
            }
        }
    }
}
