// blog css
.rts-blog {
  &.v_1 {
    background: #F5F5FF;

    .rts-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mq($md){
        flex-wrap: wrap;
        gap: 20px;
      }
      &-description {
        max-width: 410px;
        margin-bottom: 0;
        @include mq($md){
          margin: 0;
        }
      }
    }
  }

  .rts-blog-post {
    .single-blog-post {
      @include align-center;
      gap: 30px;
      position: relative;
      width: 100%;
      border: 1px solid var(--rt-line);
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      @include mq($xs){
        flex-wrap: wrap;
      }
      .blog-thumb {
        overflow: hidden;
        min-width: 190px;
        max-width: 100%;
        height: 120px;
        @include mq($lg){
          height: 150px;
        }
        &:hover{
          img{
            transform: scale(1.1);
            height: 100%;
          }
        }
        @include mq($xs){
          width: 100%;
          height: 100%;
        }
        img {
          height: 100%;
          max-width: 100%;
          min-width: 190px;
          transition: var(--transition);
          transform: scale(1);
          object-fit: cover;
          @include mq($xs){
            width: 100%;
            height: max-content;
          }
        }
      }

      .blog-content {
        padding-right: 30px;
        .post-meta {
          margin-bottom: 10px;
          @include align-center;
          @include mq($lg){
            flex-wrap: wrap;
            gap: 10px;
          }
          gap: 20px;
          @include mq($xs){
            flex-wrap: wrap;
            gap: 10px;
          }
          .rt-author,
          .rt-date {
            @include align-center;
            gap: 10px;
            a{
              color: inherit;
              transition: var(--transition);
              &:hover{
                color: var(--rt-theme);

              }
            }
          }
        }

        .post-title {
          font-size: 20px;
          font-weight: 600;
          color: var(--rt-secondary);
          position: relative;
          transition: var(--transition);
          line-height: 1.2;
          &:hover {
            color: var(--rt-theme);

          }
        }
      }
    }

    &.blog-v-full {
      position: relative;
      z-index: 1;

      .blog-thumb {
        position: relative;
        min-height: 420px;
        width: 100%;
        overflow: hidden;
        
        &:hover{
          img{
            transform: scale(1.1);
          }
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: -1;
          transform: scale(1);
        transition: var(--transition);
        }

        &::before {
          @include position-100;
          background: linear-gradient(180deg, rgba(12, 12, 15, 0.00) 0%, rgba(12, 12, 15, 0.80) 75.83%);
        }
      }

      .blog-content {
        position: absolute;
        bottom: 40px;
        left: 40px;
        color: var(--rt-white);
        z-index: 1;
        max-width: 90%;
        @include mq($xs){
          bottom: 40px;
          left: 30px;
        }
        .post-title {
          font-size: 36px;
          font-weight: 600;
          color: var(--rt-white);
          font-family: var(--font-secondary);
          line-height: 1.2;
          transition: var(--transition);
          @include mq($xs){
            font-size: 25px;
          }
          &:hover {
            color: var(--rt-theme);

          }
        }
      }
    }
  }

  &.v_2 {
    .rts-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mq($md){
        flex-wrap: wrap;
        gap: 20px;
      }
      &-description {
        max-width: 410px;
        margin-bottom: 0;
        @include mq($md){
          margin: 0;
        }
      }
    }
    &.no-bg {
      background: transparent;
    }

    .rts-border-bottom-1 {
      border-color: #D9D9D9;
    }

    .rts-blog-post .single-blog-post {
      border: 1px solid var(--rt-line);
      @include mq($xs){
        border:none;
      }
      .blog-content {
        padding-right: 30px;
        @include mq($lg){
          padding-right: 20px;
        }
      }

      .blog-thumb img {
        min-width: 160px;
      }
    }
  }

  &.v_3 {
    .single-blog {
      border: 1px solid #EFECFF;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      transition: var(--transition);
      overflow: hidden;

      &:hover {
        border-color: var(--rt-white);
      }

      &__content {
        .blog__thumb {
          overflow: hidden;
          img{
            width: 100%;
            object-fit: cover;
            transition: var(--transition);
            transform: scale(1.0);
          }
          &:hover{
            img{
              transform: scale(1.1);
            }
          }
        }

        .blog__meta {
          padding: 25px;

          &--da {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 20px;
            flex-wrap: wrap;
            .blog-date,
            .blog-author {
              display: flex;
              gap: 8px;

              span {
                i {
                  color: var(--rt-primary-1);
                }
              }
            }
          }

          .blog__title {
            margin-bottom: 30px;

            a {
              color: var(--rt-secondary);
              transition: var(--transition);
              &:hover{
                color: var(--rt-theme);
              }
            }
          }

        }

        .blog-place {
          display: flex;
          gap: 8px;

          span {
            i {
              color: var(--rt-primary-1);
            }
          }
        }
      }
    }
  }

  &.v_4 {
    .single-blog {
      border: 1px solid #EFECFF;
      border-radius: 4px;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      transition: var(--transition);
      overflow: hidden;
      padding: 20px;

      &:hover {
        border-color: var(--rt-white);
      }

      &__content {
        display: flex;
        gap: 20px;
        align-items: center;
        @include mq($xs){
          flex-wrap: wrap;
        }
        .blog__thumb {
          height: max-content;
          width: max-content;
          @include mq($xs){
            width: 100%;
          }
          img {
            min-height: 250px;
            min-width: 250px;
            object-fit: cover;
            @include mq($xs){
              width: 100%;
            }
          }
        }

        .blog__meta {
          &--da {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 20px;

            .blog-date,
            .blog-author {
              display: flex;
              gap: 8px;

              span {
                i {
                  color: var(--rt-primary-1);
                }
              }
            }
          }

          .blog__title {
            margin-bottom: 20px;

            a {
              color: var(--rt-secondary);
              transition: var(--transition);
              &:hover{
                color: var(--rt-theme);
              }
            }
          }

        }

        .blog-place {
          display: flex;
          gap: 8px;

          span {
            i {
              color: var(--rt-primary-1);
            }
          }
        }
      }
    }
  }

  &.v_5 {
    .single-blog {
      border: 1px solid #EFECFF;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      transition: var(--transition);
      overflow: hidden;
      padding: 30px;

      &:not(:last-child) {
        margin-bottom: 50px;
      }

      &:hover {
        border-color: var(--rt-white);
      }

      &__content {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: center;

        .blog__thumb {
          height: max-content;
          position: relative;

          img {
            object-fit: cover;
          }
        }

        .blog__meta {
          padding: 30px 0 20px 0;

          &--da {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 20px;

            .blog-date,
            .blog-author,
            .blog-cat {
              display: flex;
              gap: 8px;

              span {
                i {
                  color: var(--rt-primary-1);
                }
              }
            }
          }

          .blog__title {
            margin-bottom: 20px;

            a {
              color: var(--rt-secondary);
              line-height: 34px;
              transition: var(--transition);
              &:hover{
                color: var(--rt-theme);
              }
            }
          }

          .excerpt {
            margin-bottom: 45px;
          }

        }

        .blog-place {
          display: flex;
          gap: 8px;

          span {
            i {
              color: var(--rt-primary-1);
            }
          }
        }
      }
    }
  }
}

// blog details
.rts-blog-list .single-post {
  overflow: hidden;
  background: var(--rt-white);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
}

.rts-blog-list .single-post a {
  display: inline-block;
}

.rts-blog-list .single-post a img {
  width: 100%;
  object-fit: cover;
}

.rts-blog-list .single-post__meta {
  padding: 40px 50px 50px 50px;
}

.rts-blog-list .single-post__meta .author__date__cat {
  margin-bottom: 30px;
  display: flex;
  gap: 30px;
  color: #74787C;
  line-height: 100%;
  flex-wrap: wrap;
}

.rts-blog-list .single-post__meta .author__date__cat a {
  color: #74787C;
  transition: var(--transition);
  text-transform: capitalize;
}

.rts-blog-list .single-post__meta .author__date__cat a:hover {
  color: var(--rt-primary);
}

.rts-blog-list .single-post__meta .author__date__cat i {
  color: var(--rt-secondary);
  margin-right: 10px;
}

.rts-blog-list .single-post__meta .title {
  font-size: 34px;
  color: var(--rt-secondary);
  line-height: var(--lh-h3);
  transition: var(--transition);
  width: 95%;
  position: relative;
  margin-bottom: 25px;
}

@media screen and (max-width: 576px) {
  .rts-blog-list .single-post__meta .title {
    width: 100%;
  }
}

.rts-blog-list .single-post__meta .title:hover {
  color: var(--rt-primary);
}

.rts-blog-list .single-post__meta .excerpt {
  font-family: var(--font-secondary);
  margin-bottom: 40px;
  line-height: 26px;
}

.rts-blog-list .single-post__meta .readmore__btn {
  max-width: 180px;
  text-align: center;
}

.rts-sidebar {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: sticky;
  top: 120px;
}

.rts-sidebar .rts-single-widget {
  padding: 35px 30px;
  border-radius: 6px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
}

.rts-sidebar .rts-single-widget .widget-title {
  color: var(--rt-secondary);
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.rts-sidebar .rts-single-widget.search-widget form {
  position: relative;
}

.rts-sidebar .rts-single-widget.search-widget form input {
  padding: 15px 20px;
  border-radius: 4px;
  background: #F6F6F6;
  position: relative;
  transition: var(--transition);
  line-height: 100%;
}

.rts-sidebar .rts-single-widget.search-widget form input:focus {
  border-color: var(--color-third);
}

.rts-sidebar .rts-single-widget.search-widget form button {
  position: absolute;
  width: auto;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.rts-sidebar .rts-single-widget.category-widget .cat__counter {
  counter-reset: cat-counter;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rts-sidebar .rts-single-widget.category-widget .single-cat {
  padding: 16px 20px;
  border-radius: 4px;
  border: 1px solid var(--rt-line);
  counter-increment: cat-counter;
  display: flex;
  gap: 10px;
  background: var(--rt-white);
  transition: var(--transition);
  line-height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.rts-sidebar .rts-single-widget.category-widget .single-cat:hover {
  background: var(--rt-primary);
}

.rts-sidebar .rts-single-widget.category-widget .single-cat:hover a,
.rts-sidebar .rts-single-widget.category-widget .single-cat:hover .button-tab {
  color: var(--rt-white);
}

.rts-sidebar .rts-single-widget.category-widget .single-cat:hover::before {
  color: var(--rt-white);
}

.rts-sidebar .rts-single-widget.category-widget .single-cat:before {
  content: counter(cat-counter) ".";
  font-family: var(--font-secondary);
  font-weight: 600;
  font-size: 16px;
  transition: var(--transition);
  margin: 0;
}

.rts-sidebar .rts-single-widget.category-widget .single-cat a {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: var(--font-secondary);
  font-weight: 600;
  color: var(--color-secondary);
  transition: var(--transition);
}

.rts-sidebar .rts-single-widget.category-widget .single-cat .button-tab {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: var(--font-secondary);
  font-weight: 600;
  color: var(--color-secondary);
  transition: var(--transition);
  line-height: 1;
}

.rts-sidebar .rts-single-widget.recentpost-widget .recent-posts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rts-sidebar .rts-single-widget.recentpost-widget .recent-posts .single-post {
  display: flex;
  gap: 20px;
  align-items: start;
}

.rts-sidebar .rts-single-widget.recentpost-widget .recent-posts .thumb img {
  min-height: 85px;
  min-width: 85px;
  max-width: max-content;
  border-radius: 4px;
  object-fit: cover;
}

.rts-sidebar .rts-single-widget.recentpost-widget .recent-posts .meta .published {
  color: #74787C;
  font-size: 14px;
  margin-bottom: 16px;
  display: inline-block;
  line-height: 1;
}

.rts-sidebar .rts-single-widget.recentpost-widget .recent-posts .meta .published i {
  margin-right: 5px;
}

.rts-sidebar .rts-single-widget.recentpost-widget .recent-posts .meta .title {
  margin-bottom: 0;
}

.rts-sidebar .rts-single-widget.recentpost-widget .recent-posts .meta .title a {
  color: var(--rt-secondary);
  font-weight: 600;
  transition: var(--transition);
}

.rts-sidebar .rts-single-widget.recentpost-widget .recent-posts .meta .title a:hover {
  color: var(--rt-primary);
}

.rts-sidebar .rts-single-widget.gallery-widget .gallery-posts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.rts-sidebar .rts-single-widget.gallery-widget .gallery-posts .thumb img {
  min-width: 95px;
  max-width: 95px;
  min-height: 95px;
  border-radius: 4px;
}

@media screen and (max-width: 1200px) {
  .rts-sidebar .rts-single-widget.gallery-widget .gallery-posts .thumb img {
    min-width: 130px;
  }
}

.rts-sidebar .rts-single-widget.tags-widget .popular-tags .tags-style {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.rts-sidebar .rts-single-widget.tags-widget .popular-tags .tags {
  transition: var(--transition);
  margin: 0;
}

.rts-sidebar .rts-single-widget.tags-widget .popular-tags .tags a {
  border: 1px solid var(--rt-line);
  padding: 10px 20px;
  border-radius: 3px;
  display: inline-block;
  background: var(--rt-white);
  transition: var(--transition);
  text-transform: capitalize;
  line-height: 100%;
  font-size: 14px;
}

.rts-sidebar .rts-single-widget.tags-widget .popular-tags .tags a:hover {
  background: var(--rt-primary);
  color: var(--rt-white);
}

.blog-details {
  margin-bottom: 50px;
}

.blog-details__article-meta {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.blog-details__article-meta a span i {
  margin-right: 10px;
}

.blog-details__article-meta span i {
  margin-right: 10px;
}

.blog-details .blog-title {
  font-weight: 600;
}

.blog-details .rts-blockquote {
  border-radius: 3px;
  background: #F6F6F6;
  padding: 30px;
  text-align: center;
}

.blog-details .rts-blockquote h4 {
  margin-bottom: 10px;
  font-weight: 600;
}

.blog-details .rts-blockquote span {
  text-decoration: underline;
}

.blog-details .post-image {
  height: max-content;
  object-fit: cover;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 30px;
}

.blog-details .check-area-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog-details .check-area-details .single-check i {
  margin-right: 5px;
}

.blog-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

.blog-info .blog-tags {
  display: flex;
  gap: 10px;
  align-items: center;
}

.blog-info .blog-tags .tags-title {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
}

.blog-info .blog-tags__list {
  display: flex;
  gap: 10px;
}

.blog-info .blog-tags__list a {
  border: 1px solid var(--rt-border);
  padding: 10px 15px;
  border-radius: 3px;
  display: inline-block;
  background: var(--rt-white);
  transition: var(--transition);
  text-transform: capitalize;
  line-height: 100%;
  font-size: 14px;
}

.blog-info .blog-tags__list a:hover {
  background: var(--rt-primary);
  color: var(--rt-white);
}

.blog-info .blog-share {
  display: flex;
  gap: 10px;
  align-items: center;
}

.blog-info .blog-share .share {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
}

.blog-author__info {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 60px 0;
}

.blog-author__info .author-image {
  height: 170px;
  width: 170px;
  overflow: hidden;
  border-radius: 4px;
}

.blog-author__info .author-content a {
  font-size: 20px;
  font-weight: 600;
  color: var(--rt-secondary);
  font-family: var(--font-secondary);
  margin-bottom: 10px;
  display: block;
}

.blog-author__info .author-content p {
  max-width: 350px;
}

.blog-comment {
  margin-top: 40px;
}

.blog-comment__template h4 {
  font-weight: 600;
  color: var(--rt-secondary);
  margin-bottom: 30px;
}

.blog-comment__template .comment-template input,
.blog-comment__template .comment-template textarea {
  border: 1px solid var(--rt-border);
  border-radius: 3px;
  padding: 10px 15px;
}

.blog-comment__template .comment-template .input-area {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

@media screen and (max-width: 576px) {
  .blog-comment__template .comment-template .input-area {
    flex-wrap: wrap;
  }
}

.blog-comment__template .comment-template .input-area-full {
  margin-bottom: 20px;
}

.blog-comment__template .comment-template textarea {
  min-height: 200px;
  resize: none;
}

//   social media
.social__media--list {
  display: flex;
  gap: 10px;
}

.social__media--list .media {
  height: 34px;
  width: 34px;
  border-radius: 4px;
  border: 1px solid #D4DCFF;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: all 0.3s ease-in;
  font-size: 14px !important;
}

.social__media--list .media:hover {
  background: var(--rt-primary);
  border-color: var(--rt-primary);
  color: var(--rt-white);
}