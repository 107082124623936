// about Funfact
.rts-funfact {
    &-wrapper {
        background: var(--rt-primary);
        padding: 30px 100px;
        display: flex;
        justify-content: space-between;

        @include mq($sm) {
            padding: 40px;
        }

        @media screen and (min-width: 575px) and (max-width: 685px) {
            flex-wrap: wrap;
            justify-content: space-around;
            gap: 40px;
        }

        @include mq($xs) {
            flex-wrap: wrap;
            justify-content: space-around;
            gap: 40px;
        }

        .single-cta-item {
            max-width: 160px;
            position: relative;

            &:not(:last-child):after {
                position: absolute;
                height: 100%;
                width: 1px;
                background: #6E6CBA;
                content: '';
                top: 0;
                right: -70px;

                @include mq($lg) {
                    right: -40px;
                }

                @include mq($md) {
                    right: -15px;
                }

                @media screen and (min-width: 850px) and (max-width: 991px) {
                    right: -40px;
                }

                @media screen and (min-width: 992px) and (max-width: 1075px) {
                    right: -30px;
                }

                @include mq($sm) {
                    right: -20px;
                }

                @media screen and (min-width: 575px) and (max-width: 685px) {
                    display: none;
                }

                @include mq($xs) {
                    display: none;
                }
            }

            h2 {
                color: var(--rt-white);
                margin-bottom: 5px;
            }

            p {
                color: var(--rt-white);
            }
        }
    }

    &.v_2 {
        .single-cta-item {
            max-width: 230px;
            text-align: center;
        }
    }

    &.v_3 {
        .rts-funfact-wrapper {
            padding: 40px;

            @include mq($md) {
                flex-wrap: wrap;
                justify-content: center;
                gap: 50px;
            }
        }

        .single-cta-item {
            max-width: 265px;
            text-align: center;

            &:not(:last-child)::after {
                right: -50px;
                @include mq($md){
                    display: none;
                }
            }

            h2 {
                -webkit-text-fill-color: var(--rt-white);
                -webkit-text-stroke: 0px;
            }
        }
    }
}