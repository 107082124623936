

// course single scss

.course-details-btn-wrapper{
    padding-bottom: 30px;
    border-bottom: 1px solid #DDD8F9;
    ul{
        padding-left: 0;
        margin: 0;
        border: none;
        display: flex;
        align-items: center;
        gap: 10px;
        li{
            margin: 0;
            padding: 0;
            button.nav-link{
                padding: 15px 20px;
                border: none;
                border-radius: 4px;
                border: 1px solid  #DDD8F9;
                color: #553CDF;
                &.active{
                    background: #553CDF;
                    color: #fff;
                }
            }
        }
    }
    &.full-width{
        justify-content: space-between;
        ul{
            justify-content: space-between;
            gap: 0;
            li{
                flex-basis: 25%;
                button{
                    border-radius: 0;
                }
            }
        }
    }
}

.course-content-wrapper{
    .title{
        font-size: 24px;
    }
    p.disc{
        margin-bottom: 30px;
    }
    .module-wrapper{
        padding: 30px;
        border: 1px solid #DDD8F9;
        border-radius: 6px;
        .inner-content{
            display: flex;
            align-items: flex-start;
            gap: 60px;
        }
        .single-wrapper{
            .single-codule{
                display: flex;
                align-items: center;
                gap: 8px;
                margin: 10px 0;
            }
        }
    }
}

.wrapper-bottom-course-details-page{
    padding: 30px;
    border-radius: 6px;
    border: 1px solid  #DDD8F9;
    margin-left: 5px;
    margin-right: 5px;
}

.course-content-wrapper-main{
    .title{
        margin-bottom: 10px;
    }
    .accordion{
        .accordion-item{
            border: 1px solid #DDD8F9;
            border-bottom: none;
            &:last-child{
                border: 1px solid #DDD8F9; 
            }
            .accordion-body{
                padding: 20px;
              .play-vedio-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 7px 0;
                .left{
                    i{
                        color: #110C2D;
                    }
                    span{
                        color: #553CDF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;
                        text-decoration-line:underline;
                    }
                }
                .right{
                    .play{
                        color:  #553CDF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;
                        text-decoration-line: underline;
                        margin-right: 20px;
                    }
                }
              }  
            }
        }
        .accordion-header{
            button{
                padding: 15px 25px;
                background: transparent;
                display: flex;
                align-items: center;
                box-shadow: none;
                justify-content: space-between;
                &[aria-expanded="true"]{
                    border-bottom: 1px solid #DDD8F9;
                }
                &:focus{
                    box-shadow: none;
                }
                &::after{
                    display: none;
                }
                span{
                    display: block;
                    color:  #110C2D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px;
                }
            }
        }
    }
}


.single-instructor-area-details{
    padding: 40px;
    border-radius: 6px;
    border: 1px solid  #DDD8F9;
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.15);
            }
        }
    }
    .thumbnail{
        display: block;
        min-width: max-content;
        overflow: hidden;
        border-radius: 4px;
        img{
            transition: .3s;
        }
    }
    .inner-instrustor-area{
        .title{
            margin-bottom: 5px;
        }
        .deg{
            color:  #737477;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }
        .stars-area-wrapper{
            display: flex;
            align-items: center;
            gap: 30px;
            margin: 12px 0;
            .stars-area{
                span{
                    color: #110C2D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                }
                i{
                    color: #FFA41C;
                }
            }
        }
        p.disc{
            margin-bottom: 20px;
        }
        .follow-us{
            display: flex;
            align-items: center;
            ul{
                margin-left: 30px;
                padding: 0;
                display: flex;
                align-items: center;
                gap: 15px;
                list-style: none;
                margin-top: 0;
                margin-bottom: 0;
                li{
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}

.rating-top-main-wrapper{
    display: flex;
    align-items: center;
    gap: 50px;
}
.rating-area-main-wrapper{
    padding: 30px;
    border-radius: 4px;
    border: 1px solid  #DDD8F9;
    text-align: center;
    flex-basis: 27%;
    .title{
        color:  #110C2D;
        font-size: 96px;
        font-style: normal;
        font-weight: 500;
        line-height: 80px;
        margin-bottom: 5px;
    }
    .stars-wrapper{
        margin-bottom: 10px;
        i{
            color: #FFA41C;
        }
    }
    span{
        color: #110C2D;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }
}

.rating-main-wrapper{
    padding: 30px;
    border-radius: 4px;
    border: 1px solid  #DDD8F9;
}


.progress-wrapper-main{
    flex-basis: 69%;
    .single-progress-area-h{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 13px 0;
        width: 100%;
        .progress{
            width: 70%;
            border-radius: 6px;
            .progress-bar{
                background: #FFA41C;
            }
        }
    }
}


.indevidual-rating-area{
    padding-top: 30px;
    border-top: 1px solid #DDD8F9;
    margin-top: 40px;
    .author-area{
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 25px;
        span{
            color:  #110C2D;
            font-family: Hind;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
        }
        .stars{
            display: flex;
            align-items: center;
            gap: 3px;
            i{
                color: #FFA41C;
            }
        }
    }
    p.disc{
        margin-bottom: 25px;
    }
    .like-love-area{
        display: flex;
        align-items: center;
        gap: 15px;
        a{
            display:block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: #737477;
            border: 1px solid #DDD8F9;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover{
                background: var(--rt-primary);
                color: #fff;
            }
        }
    }
}

.right-course-details{
    margin-top: -350px;
    .course-side-bar{
        padding: 30px;
        border: 1px solid #DDD8F9;
        border-radius: 6px;
        background: #fff;
        position: relative;
        .thumbnail{
            position: relative;

        }
        .price-area{
            display: flex;
            align-items: center;
            gap: 15px;
            margin-top: 35px;
            margin-bottom: 20px;
            .title{
                margin-bottom: 0;
                color:  #110C2D;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
            }
            h4{
                margin-bottom: 0;
                color:  #737477;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
            }
            .discount{
                padding: 3px 10px;
                border-radius: 3px;
                background: var(--rt-primary);
                color: #fff;
            }
        }
        .clock-area{
            i,
            span{
                color: #BC0303;
            }
        }
        .rts-btn{
            margin-bottom: 20px;
            margin-top: 20px;
            display: block;
            width: 100%;
            max-width: 100%;
            text-align: center;
        }
        .what-includes{
            span.m{
                display: flex;
                justify-content: center;
                padding: 10px 0;
                padding-bottom: 25px;
            }
            .title{
                color:  #110C2D;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 10px;
            }
            .single-include{
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #DDD8F9;
                padding-bottom: 15px;
                padding-top: 15px;
                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
    }
}


.course-single-information{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #DDD8F9;
    padding-top: 15px;
    &.last{
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
    }
    .title{
        margin-bottom: 20px;
        color:  #110C2D;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
    }
    .body{
        .author{
            margin: 15px 0;
            display: flex;
            align-items: center;
            gap: 15px;
        }
        .single-check{
            display: flex;
            align-items: center;
            gap: 12px;
            margin: 15px 0;
            color:  #737477;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            i{
                color: var(--rt-primary);
            }
        }
        .tags-wrapper{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            span{
                display: block;
                padding: 5px 8px;
                border: 1px solid #DDD8F9;
                color: #110C2D;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                cursor: pointer;
                transition: .3s;
                &:hover{
                    background: var(--rt-primary);
                    color: #fff;
                }
            }
        }
    }
}


.social-share-course-side-bar{
    ul{
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 20px;
        list-style: none;
        li{
            margin: 0;
            padding: 0;
            a{
                i{
                    font-size: 24px;
                    color: #110C2D;
                }
            }
        }
    }
}


.right-course-details{
    .rts-course-category-area{
        padding-left: 50px;
        border-left: 2px solid #DDD8F9;
        margin-bottom: 0;
        padding-bottom: 0;
        .checkbox-filter.filter-body{
            margin: 0;
            padding: 0;
            border: none;
            margin-bottom: 50px;
        }
    }
    .single-filter-left-wrapper{
        .title{
            color: #110C2D;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 10px;
            margin-bottom: 20px;
        }
    }
}


.our-latest-course-start-right-side-bar{
    margin-bottom: 60px;
    .title{
        color:#110C2D;
        font-family: Hind;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }
    .single-course-list-wrapper{
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;
        &:hover{
            img{
                transform: scale(1.15);
            }
        }
        .thumbnail{
            min-width: max-content;
            display: block;
            overflow: hidden;
            border-radius: 4px;
            img{
                transition: .3s;
            }
        }
        .inner-content{
            a{
                .title{
                    color:#110C2D;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    margin-bottom: 7px;
                    transition: .3s;
                    &:hover{
                        color: var(--rt-primary);
                    }
                }
            }
            .pricing-area{
                display: flex;
                align-items: center;
                gap: 20px;
                .none{
                    color:  #737477;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 28px;
                    text-decoration-line: strikethrough;
                }
                span{
                    color:  #110C2D;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                }
            }
        }
    }
}

.add-area-sidebar{
    position: relative;
    .content{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        .title{
            color: #110C2D;
            text-align: center;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px;
        }
        .rts-btn{
            margin: auto;
            background: #110C2D !important;
            padding: 13px 25px;
            transition: .3s;
            &:hover{
                background: var(--rt-primary) !important;
                color: #fff !important;
            }
        }
    }
}