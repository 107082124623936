// Testimonial Area
.rts-testimonial {
    &.v_1 {}

    &.v_2 {
        background: #F5F5FF;

        .rts-testimonial-box {
            padding: 0;
            border: none;
            background: var(--rt-white);
        }

        .single-testimonial {
            padding-top: 100px;
            padding-bottom: 80px;
            padding-right: 80px;

            @include mq($lg) {
                padding-right: 60px;
                padding-bottom: 40px;
                padding-top: 60px;
            }

            @include mq($md) {
                padding: 40px 60px;
            }
        }

        .testmonial-v2 {
            overflow: hidden;
        }

        .rts-testimonial-box .testimonial-item-image {
            >img {
                min-width: 460px;
                height: 560px;
                @include mq($lg){
                    min-width: 100%;
                }
            }
        }

        .testimonial-item-image {
            position: relative;

            &::before {
                @include position-100;
                background: rgba(0, 0, 0, 0.20);
                left: 0;
                top: 0;
            }

            .video-play {
                @include position;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                cursor: pointer;

                img {
                    height: 82px;
                    width: 82px;
                }
            }
        }

        .rt-review p {
            color: var(--rt-primary);
        }

        .rt-author-meta .rt-author-img {
            border-color: var(--rt-primary-1) !important;
        }

        .rts-swiper-dots {
            gap: 10px;

        }

        // pagination
        .testimonial-item .swiper-pagination {
            bottom: 120px;
            max-width: max-content;
            display: flex;
            gap: 5px;
            left: 50%;
            @include mq($md){
                bottom: 60px;
            }
            transform: translateX(-50%);
            .swiper-pagination-bullet {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background: var(--rt-line);
                opacity: 1;

                &-active {
                    background: var(--rt-primary);
                }
            }
        }
    }
}

.rts-testimonial-box {
    padding: 50px 80px 50px 50px;
    border: 1px solid var(--rt-line);

    @include mq($lg) {
        padding: 30px;
    }

    @include mq($md) {
        padding: 40px;
    }

    @include mq($sm) {
        padding: 30px;
    }
    @include mq($xs){
        padding: 0;
        border: 0;
    }
    .testimonial-item {
        display: flex;
        align-items: center;
        gap: 80px;

        @include mq($lg) {
            gap: 40px;
        }

        @include mq($md) {
            flex-wrap: wrap;
        }

        &-image {
            min-width: 460px;
            max-width: max-content;
            height: max-content;

            @include mq($lg) {
                min-width: 360px;
                max-width: max-content;
            }

            @include mq($md) {
                min-width: 100%;
            }

            img {
                width: 100%;
                height: 500px;
                object-fit: cover;
                object-position: top;

                @include mq($xs) {
                    height: 100%;
                }
            }
        }

        &-content {
            @include mq($lg) {
                max-width: 500px;
            }

            @include mq($md) {
                max-width: 100%;
            }

            .single-testimonial {
                .rt-icon {}

                .rt-review {
                    .rating-star {
                        i {
                            color: var(--rt-rating);
                        }
                    }
                }

                .testimonial-text {
                    font-size: 22px;
                    line-height: 38px;

                    @include mq($xs) {
                        font-size: 18px;
                        line-height: 32px;
                    }
                }

                .rt-testimonial-author {
                    .rt-author-meta {
                        @include mq($xs) {
                            flex-wrap: wrap;
                        }

                        .rt-author-img {
                            height: 70px;
                            width: 70px;
                            border-radius: 50%;
                            border: 1px solid var(--rt-line);
                            display: grid;
                            place-items: center;
                            overflow: hidden;

                            img {
                                height: 60px;
                                width: 60px;
                                object-fit: cover;
                                border-radius: 50%;
                            }
                        }

                        .rt-author-info {
                            h5 {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .testimonial-arrow {
                display: flex;
                gap: 10px;
                position: absolute;
                right: 0;
                bottom: 0;

                @include mq($xs) {
                    display: none;
                }

                .rt-slider-btn {
                    border-color: #998AEC;
                    height: 40px;
                    width: 40px;
                    border-radius: 0;
                    bottom: 0;
                    top: 20px;
                    right: 0;
                    left: unset;
                    position: relative;

                    &:hover {
                        background: var(--rt-secondary);
                        border-color: var(--rt-secondary);

                        i {
                            color: var(--rt-white);
                        }
                    }

                    i {
                        color: var(--rt-primary);
                        font-size: 16px;
                    }
                }
            }
        }
    }
}