
.side-bar {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: -100%;
    width: 380px;
    padding: 40px 35px;
    padding-top: 50px;
    height: 100%;
    display: block;
    background-color: white;
    backdrop-filter: blur(7px);
    z-index: 1900;
    transition: all 600ms ease;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow: visible;
    @media #{$large-mobile} {
        width: 315px;
    }
    .inner-main-wrapper-desk{
        .thumbnail{
            display: flex;
            justify-content: center;
            img{
                width: 60%;
                margin: auto;
            }
        }
        .inner-content{
            text-align: center;
            margin-top: 30px;
            p{
            max-width: 100%;
            text-align: center;
            margin: auto;
            font-size: 14px;
            }
            .title{
                font-weight: 600;
            }
            .footer{
                padding-top: 50px;
                margin-top: 40px;
                border-top: 1px solid #e8e8e8;
                .title{
                    font-weight: 500;
                }
                a.rts-btn{
                    margin: auto;
                }
            }
            .contact-information--sidebar{
                margin-top: 50px;
                .title{
                    margin-bottom: 20px;
                    font-size: 24px;
                }
                .single-info{
                    margin: 15px 0;
                    a{
                        // color: #2f3022;
                        // font-weight: 500;
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
                .rts-social-style-one{
                    margin-top: 40px;
                    ul{
                        padding: 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 15px;
                        list-style: none;
                        li{
                            margin: 0;
                            a{
                                height: 45px;
                                width: 45px;
                                border-radius: 50%;
                                border: 1px solid #dfdfdf;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: var(--transition);
                                &:hover{
                                    background: var(--rt-primary);
                                    border: 1px solid transparent;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}
.side-bar.show {
    right: 0;
    overflow-y: auto;
}
.side-bar button {
    max-width: max-content;
    margin-right: auto;
    margin-left: -55px;
    margin-top: 0;
    position: absolute;
    border: 0;
    i{
        color: #ffffff;
        height: 50px;
        width: 50px;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        margin-top: -53px;
        font-size: 27px; 
        background: var(--rt-theme);   
    }
}



#anywhere-home {
    cursor: url(../images/banner/shape/close.png), auto;
    background: #0e1013;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms ease-in-out;
    pointer-events: none;
    z-index: 50;
}

#anywhere-home.bgshow {
    background: #0e1013;
    opacity: 70%;
    visibility: visible;
    pointer-events: visible;
    z-index: 999;
    top: 0;
}


.metismenu .mm-collapse:not(.mm-show) {
    display: none;
}
.index-three{
    .side-bar{
        .inner-main-wrapper-desk{
            display: none !important;
        }
        .mobile-menu-main{
            display: block !important;
        }
    }
}

.offcanvase__banner{
    &--content{
        position: relative;
        img{
            width: 100%;
        }
        .rts-theme-btn{
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translate(-50%);
            font-size: 14px;
            padding: 8px 30px;
            border-radius: 4px;
        }
    }
}
.offcanvase__info{
    margin-top: 50px;
    &--content{
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: left;
        a{
            display: flex;
            gap: 10px;
            transition: var(--transition);
            &:hover{
                color: var(--rt-secondary);
            }
            span{
                color: var(--rt-secondary);
            }
        }
        &--social{

            > p{
                text-align: left;
                margin: 0 !important;
            }
            display: flex;
            align-items: center;
            gap: 20px;
            .social__links{
                display: flex;
                gap: 10px;
            }
        }
    }
}
