// button style here


.rts-btn {
    display: block;
    max-width: max-content;
    padding: 18px 34px;
    border-radius: 4px;
    transition: .3s;
    font-weight: 500;

    &.btn-primary {
        background: var(--rt-primary) !important;
        color: #fff;
        border: 1px solid transparent;

        &:hover {
            border: 1px solid #BBB1F2;
            background: transparent !important;
            color: var(--rt-primary) !important;
        }
    }

    &.with-arrow {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &.btn-border {
        border: 1px solid #BBB1F2;
        color: var(--rt-primary);

        &:hover {
            background: var(--rt-primary);
            color: #fff;
        }
    }

    &.btn-primary-white {
        background: #fff !important;
        color: #110C2D;
        border: 1px solid transparent;

        &:hover {
            background: transparent !important;
            border-color: #fff;
            color: #fff;
        }
    }
}

.rts-theme-btn {
    max-width: max-content;
    transition: var(--transition);
    font-weight: 500;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 15px 30px;
    background: var(--rt-theme);
    color: var(--rt-white);
    overflow: hidden;
    z-index: 1;

    &::before {
        @include position-100;
        left: -70%;
        background: var(--rt-secondary);
        z-index: -1;
        transition: var(--transition);
        opacity: 0;
    }

    &:hover {
        color: var(--rt-white);

        &::before {
            opacity: 1;
            left: 0;
        }
    }
    &.primary{
        color: var(--rt-white);
        background: var(--rt-primary);
        &.btn-arrow{
            min-width: 200px;
        }
        &::before{
            background: var(--rt-theme);
        }
    }
    &.btn-arrow {
        min-width: 220px;
        span {
            transform: rotate(-35deg);
            margin-left: 10px;
            transition: var(--transition);
        }
        &:hover{
            span{
                transform: rotate(0) !important;
            }
        }
    }
    &.with-arrow{
        span {
            transform: rotate(-35deg);
            margin-left: 10px;
            transition: var(--transition);
        } 
        &:hover{
            span{
                transform: rotate(0) !important;
            }
        }
    }

    &.full-btn {
        max-width: 100%;
    }

    &.v2 {
        background: var(--rt-primary);
        &::before {
            @include position-100;
            left: -70%;
            background: var(--rt-theme);
            z-index: -1;
            transition: var(--transition);
            opacity: 0;
        }

        &:hover {
            color: var(--rt-white);

            &::before {
                opacity: 1;
                left: 0;
            }
        }
    }
    &.btn-white{
        background: var(--rt-white);
        color: var(--rt-secondary);
        &:hover{
            color: var(--rt-white);
        }
    }
    &.border-btn{
        background: transparent;
        border: 1px solid var(--rt-primary);
        color: var(--rt-primary);
        line-height: 100%;
        &:hover{
            color: var(--rt-white);
        }
    }
    span{
        margin-left: 10px;
    }
}

.rts-nbg-btn {
    &.btn-arrow {
        span {
            transform: rotate(-35deg);
            margin-left: 5px;
            display: inline-block;
            transition: var(--transition);
        }
        &:hover{
            span{
                transform: rotate(0);
            }
        }
    }
}
.rts-arrow{
    color: var(--rt-primary);
    font-weight: 600;
    display: inline-block;
    span{
        margin-left: 5px;
    }
    
}

// swiper slider button
// arrow button style
.rt-slider-btn {
    position: absolute;
    top: 50%;
    background: transparent;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    color: var(--rt-body);
    left: 100px;    
    z-index: 9;
    transform: translateY(-50%);
    font-size: 24px;
    transition: var(--transition);
    border: 1px solid var(--rt-body);
    display: grid;
    place-items: center;
    cursor: pointer;
    @include mq($lg){
        left: 50px;
    }
    &:hover {
        background: var(--rt-theme);
        border-color: var(--rt-theme);
        i{
            color: var(--rt-white);
        }
    }

    i {
        color: var(--rt-body);
    }

    &.rt-prev {
        left: auto;
        right: 100px;
        @include mq($lg){
            right: 50px;
        }
    }
}

// swiper dots
.rts-swiper-dots {
    display: flex;
    gap: 15px;
    max-width: max-content;
    .swiper-pagination-bullet {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: #616161;
        opacity: 1;

        &-active {
            background: #D9D9D9;
        }
    }
}