.rts-footer{

    &.v_1{
        background: var(--footer-bg);
        padding-bottom: 80px;
    }
    &.v_2{
        .rts-footer-newsletter{
            display: flex;
            justify-content: center;
            border-bottom: 1px solid #242424;
            padding-bottom: 50px;
            margin-bottom: 60px;
        }
        background: var(--footer-bg);
        &.rts-footer-padding{
            padding-top: 50px;
            padding-bottom: 60px;
        }
        .rts-newsletter-box-content{
            display: flex;
            align-items: center;
            h4{
                color: var(--rt-white);
                font-weight: 600;
                margin-bottom: 0;
            }
            .newsletter-form form input[type="email"]{
                border-color: var(--rt-theme);
            }
            .newsletter-form form button{
                color: var(--rt-secondary);
                &:hover{
                    color: var(--rt-white);
                }
            }
            .newsletter-form form button.rts-nbg-btn::before{
                background: var(--rt-theme);
            }
        }

    }
    &.rts-footer-padding{
        padding: 100px 0;
    }
}
.rts-footer-widget{
    &.ml--30{
        @include mq($md){
            margin-left: 0 !important;
        }
    }
    h6{
        text-decoration: underline;
        color: var(--rt-white);
        margin-bottom: 25px;
    }
    .rts-contact-link{
        a{
            display: block;
            &:not(:last-child){
                margin-bottom: 15px;
            }
            transition: var(--transition);
            &:hover{
                color: var(--rt-theme);
            }
        }
    }
    .rts-footer-menu{
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            li{

                a{
                    transition: var(--transition);
                    &:hover{
                        color: var(--rt-theme);
                    }
                }
            }
        }
    }
    .rts-post-widget{
        ul{
            margin: 0;
            .single-post{
                @include align-center;
                gap: 20px;
                margin: 0;
                &:not(:last-child){
                   padding-bottom: 25px;; 
                }
                .blog-thumb{
                    img{
                        height: 80px;
                        max-width: 80px;
                        transform: scale(1);
                        object-fit: cover;
                    }
                }
                .post-content{
                    span.rt-date{
                        display: block;
                        margin-bottom: 5px;
                    }
                    a{
                        font-weight: 600;
                        color: var(--rt-white);
                        font-size: 18px;
                        transition: var(--transition);
                        &:hover{
                            color: var(--rt-theme);
                        }
                    }
                }
            }
        }
    }
}
.rts-footer-copy-right{
    &.v_1{
        padding: 16px 0;
        background: var(--footer-bg);
        border-top: 1px solid #242424;
    }
    &.v_2{

    }
}