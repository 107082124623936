// newsletter
.rts-newsletter {
    &.v_1 {
        .rts-newsletter-box {
            height: 200px;
            @include align-center;
            padding: 0 80px;
            @include mq($md){
                padding: 0 50px;
            }
            @include mq($xs){
                padding: 30px;
                height: 100%;
            }
        }
        &.rts-cta-background{
            background: var(--rt-white);
            position: relative;
            &::before{
                @include position;
                inset: 0;
                height: 60%;
                bottom: 0;
                background: var(--footer-bg);
                top: unset;
            }
        }
    }
}

.newsletter-bg {
    background-image: url(../images/newsletter/bg-1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.rts-newsletter-box {
    &>* {
        position: relative;
        z-index: 1;
    }

    &::before {
        @include position-100;
        background: var(--rt-theme);
        opacity: .9;
    }

    &-content {
        @include rt-between;
        width: 100%;
        align-items: center;
        gap: 30px;
        @include mq($sm){
            justify-content: center;
            flex-wrap: wrap;
            gap: 30px;
        }
        .newsletter-title{
            @include mq($sm){
                max-width: 100%
            }
        }
        .newsletter-form {
            form {
                display: flex;
                gap: 10px;
                @include mq($xs){
                    flex-wrap: wrap;
                }
                input[type='email'] {
                    border: 1px solid var(--rt-white);
                    padding: 13px 20px;
                    min-width: 340px;
                    max-width: 100%;
                    @include mq($xs){
                        min-width: 100%;    
                    }
                    @include mq($lg){
                        min-width: 260px;    
                    }
                    color: var(--rt-white);

                    &::placeholder {
                        color: var(--rt-white);
                    }
                }

                button.rts-nbg-btn {
                    background: var(--rt-white);
                    position: relative;
                    overflow: hidden;
                    z-index: 1;
                    padding: 15px 20px;
                    @include mq($md){
                        min-width: 145px;
                        max-width: max-content;
                    }
                    @include mq($xs){
                        padding: 15px 30px;
                        min-width: 100%;
                    }
                    &::before {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        content: "";
                        left: 0;
                        top: 0;
                        left: -70%;
                        background: var(--rt-secondary);
                        z-index: -1;
                        transition: var(--transition);
                        opacity: 0;
                    }
                    &:hover{
                        color: var(--rt-white);
                        border-color: var(--rt-secondary);
                        &::before{
                            opacity: 1;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}